<template>
  <div class="tags">

    <b-container>
      <OnboardingHeader
        :time="time"
        :step="step"
        :title="title"
      ></OnboardingHeader>
      <TagsBox
        :tagCategory="tagsRef"
        :title="title"
        :LikeFields="LikeFields"
        :DislikeFields="DislikeFields"
        :likesText="likesText"
        :dislikesText="dislikesText"
        :oid="oid"
      ></TagsBox>
    </b-container>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";
import TagsBox from "@/components/interfaces/TagsBox.vue";
export default {
  components: { OnboardingHeader, TagsBox },
  data() {
    return {
      currentCat: 0,
      oid: 2,
      step: "3",
      title: "Your Career, until now.",
      time: "Less than 3 Minutes",
      taxonomy: "experiences",
      tagsRef: "experiences",
      LikeFields: ["Jobs I Enjoyed", "Category"],
      DislikeFields: ["Jobs I Didn't Care For", "Category"],
      likesText:
        'This will be a list of your jobs that you really enjoyed for one reason or another',
      dislikesText:
        "This will be a list of your jobs that you really did not enjoy, and would not even want to bring them up in conversation.",
      currentUser: this.$store.getters.getUID,
    };
  },
};
</script>
<style scoped></style>
